
import { defineComponent } from 'vue';
import { ICountry } from '@/interfaces/country';
import { IProduct } from '@/interfaces/product';
import Base from '@/mixins/Base.vue';
import Planning from '@/pages/products/partials/Planning.vue';
import Forms from '@/pages/products/partials/Forms.vue';
import Objects from '@/pages/products/partials/Objects.vue';
import Pricing from '@/pages/products/partials/Pricing.vue';
import Subproducts from '@/pages/products/partials/Subproducts.vue';
import Surveys from '@/pages/products/partials/Surveys.vue';
import Variants from '@/pages/products/partials/Variants.vue';
import Associated from '@/pages/products/partials/Associated.vue';
import General from '@/pages/products/partials/General.vue';

export default defineComponent({
    components: {
        General,
        Planning,
        Associated,
        Objects,
        Pricing,
        Subproducts,
        Surveys,
        Forms,
        Variants,
    },
    mixins: [Base],
    beforeRouteUpdate(to) {
        this.currentTab = 'general';
        this.getItem(to.params.id);
    },
    emits: [
        'reload',
    ],
    data() {
        return {
            currentTab: 'general',
            item: undefined as any | IProduct,
            tags: [] as Array<any>,
        };
    },
    mounted() {
        this.getItem(this.$route.params.id);
    },
    methods: {
        pushTo(platform: string): void {
            this.get(`products/${this.$route.params.id}/push`, {
                params: {
                    platform,
                },
            })
                .then(() => {
                    this.getItem(this.$route.params.id);
                });
        },
        pullFrom(platform: string): void {
            this.get(`products/${this.$route.params.id}/pull`, {
                params: {
                    platform,
                },
            })
                .then(() => {
                    this.getItem(this.$route.params.id);
                });
        },
        getItem(id: any): void {
            this.get(`products/${id}`)
                .then(({ data }: any) => {
                    this.item = data.data;
                    (this.$refs.form as any).setValues(this.item);
                });
        },

        saveItem(): void {
            this.put(`products/${this.$route.params.id}`, {
                ...this.item,
                platform_id: this.item.platform ? this.item.platform.id : null,
                tags: this.item.tags.map((t: any) => t.id),
                name: this.item.translations.name,
                unit: this.item.translations.unit,
                description: this.item.translations.description,
                forms_client_information: this.item.translations.forms_client_information,
                question: this.item.translations.question,
                building_type_id: this.item.building_type ? this.item.building_type.id : null,
                region_id: this.item.region ? this.item.region.id : null,
                category_id: this.item.category ? this.item.category.id : null,
                forms_report_type_id: this.item.forms_report_type ? this.item.forms_report_type.id : null,
                variant_parent_id: this.item.variant_parent ? this.item.variant_parent.id : null,
                variants: this.item.variants.map((v: any) => ({
                    id: v.id,
                    code: v.code,
                    building_type_id: v.building_type ? v.building_type.id : null,
                    region_id: v.region ? v.region.id : null,
                })),
                countries: (this.item.countries ?? []).map((country: ICountry) => country.id),
            })
                .then(() => {
                    this.$router.push({ name: 'products.list' });
                    this.$emit('reload');
                });
        },

    },
});
